module.exports={	
	"API_URL": "/data",
    "DEFAULT_LANGUAGE": "fr",
    "ENABLE_HTML_MODE": true,
    "FAVICON":{
        "png":"logo_pnr_briere-min.png"
    },
    "AVAILABLE_LANGUAGES": {
        "fr": "Français"
    },
    "ENABLED_LANGUAGES": ["fr"],  
    "HOME_TEMPLATE_FILE": {
        "fr": "custom-home-fr.html",
        "it": "",
        "en": "",
        "de": "",
        "nl": ""
    },
    "HEADER_TEMPLATE_FILE": "custom-header.html",
    "BACKOFFICE_URL": "admin.rando-briere.lenaturographe.fr", 
    "LEAFLET_CONF": {
        "CENTER_LATITUDE": 47.4020647,
        "CENTER_LONGITUDE": -2.2799718,
        "DEFAULT_ZOOM": 11,
        "DEFAULT_MIN_ZOOM": 11,
        "DEFAULT_MAX_ZOOM": 17
    },
    "FILTER_BY_VIEWPORT_DEFAULT" : "true",
    "DEFAULT_ACTIVE_CATEGORIES" : [],
    "GOOGLE_ANALYTICS_ID" : "UA-153706701-7",
    "PDF_LINK_IN_DOWNLOAD" : true,
    "FACEBOOK_APP_ID" : "227315432584858",
    "ORTHOPHOTO_TILELAYERS_NAME": "Photo",
    "ORTHOPHOTO_TILELAYERS": {
        "LAYER_URL": "https://wxs.ign.fr/ortho/geoportail/wmts?&REQUEST=GetTile&SERVICE=WMTS&VERSION=1.0.0&STYLE=normal&TILEMATRIXSET=PM&FORMAT=image/jpeg&LAYER=ORTHOIMAGERY.ORTHOPHOTOS&TILEMATRIX={z}&TILEROW={y}&TILECOL={x}",
        "OPTIONS": {
            "id": "satellite",
            "attribution": "&copy; IGN - GeoPortail"
        }
    }
}